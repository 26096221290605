import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

//Slice

const slice = createSlice({
  name: "admindatahub",
  initialState: {
    organisations: [],
    sites: [],
    devices: [],
    userdevices: [],
    users: [],
    downloads: [],
    pimlogs: [],
    pimlogsite: [],
    pimerror: [],
    datas: [],
    datasday: [],
    datasite: [],
    datasdaysite: [],
    dataexport: [],
  },
  reducers: {
    getOrgasAdmin: (state, action) => {
      state.organisations = action.payload;
    },
    getUsersAdmin: (state, action) => {
      state.users = action.payload;
    },
    getSitesAdmin: (state, action) => {
      state.sites = action.payload;
    },
    getDevicesAdmin: (state, action) => {
      state.devices = action.payload;
    },
    getDevices: (state, action) => {
      state.userdevices = action.payload;
    },
    getDownloadsAdmin: (state, action) => {
      state.downloads = action.payload;
    },
    getPimlogsAdmin: (state, action) => {
      state.pimlogs = action.payload;
    },
    getPimlogsAdminsite: (state, action) => {
      state.pimlogsite = action.payload;
    },
    getPimErrorAdmin: (state, action) => {
      state.pimerror = action.payload;
    },
    addOrga: (state, action) => {
      state.organisations = [...state.organisations, action.payload];
    },
    removeOrga: (state, action) => {
      state.organisations = state.organisations.filter(
        (item) => item.id_orga !== action.payload
      );
    },
    updateStateOrga: (state, action) => {
      state.organisations = state.organisations.map((item) => {
        if (item.id_orga === action.payload.id_orga) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    addSite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
    removeSite: (state, action) => {
      state.sites = state.sites.filter(
        (item) => item.data_type !== action.payload
      );
    },
    attachdevicesite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
    detachdevicesite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
    updateStateSite: (state, action) => {
      state.sites = state.sites.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    addDevice: (state, action) => {
      state.devices = [...state.devices, action.payload];
    },
    removeDevice: (state, action) => {
      state.devices = state.devices.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateDevice: (state, action) => {
      state.devices = state.devices.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateUser: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getDatas: (state, action) => {
      state.datas = action.payload;
    },
    getDatasite: (state, action) => {
      state.datasite = action.payload;
    },
    getPimlogs: (state, action) => {
      state.pimlogs = action.payload;
    },
    getPimlogsite: (state, action) => {
      state.pimlogsite = action.payload;
    },
    getDatasDay: (state, action) => {
      state.datasday = action.payload;
    },
    getDatasDaySite: (state, action) => {
      state.datasdaysite = action.payload;
    },
    getDataExport: (state, action) => {
      state.dataexport = action.payload;
    },
  },
});

export default slice.reducer;

const {
  getOrgasAdmin,
  getUsersAdmin,
  getSitesAdmin,
  getDevicesAdmin,
  getDevices,
  getDownloadsAdmin,
  getPimlogsAdmin,
  getPimlogsAdminsite,
  getPimErrorAdmin,
  addOrga,
  removeOrga,
  updateStateOrga,
  addSite,
  attachdevicesite,
  detachdevicesite,
  removeSite,
  updateStateSite,
  addDevice,
  updateStateDevice,
  removeDevice,
  addUser,
  updateStateUser,
  removeUser,
  getDatas,
  getDatasite,
  getDatasDay,
  getDatasDaySite,
  getPimlogs,
  getPimlogsite,
  getDataExport,
} = slice.actions;

function CheckConfigAxios() {
  //We check if token is loaded into Axios Config
  if (axiosInstance.defaults.headers.common.Authorization == null) {
    //console.log("NO TOKEN IN AXIOS CONFIG, NEED TO LOAD ONE !");
    let token = localStorage.getItem("accessToken");
    if (isValidToken(token)) {
      axiosInstance.defaults.headers.common.Authorization = token;
    } else {
      console.log("YOU HAVE TO RECONNECT");
    }
  } else {
    if (!isValidToken(axiosInstance.defaults.headers.common.Authorization)) {
      console.log("YOU HAVE TO RECONNECT");
    }
  }
}

export const fetchOrganisationsAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/organisations`)
      .then((response) => dispatch(getOrgasAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchUsersAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/users`)
      .then((response) => dispatch(getUsersAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchSitesAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/sites`)
      .then((response) => dispatch(getSitesAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDevicesAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/devices`)
      .then((response) => dispatch(getDevicesAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};
export const fetchDevices = (id_orga) => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/organisations/${id_orga}/devices`)
      .then((response) => dispatch(getDevices(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDownloadAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/files`)
      .then((response) => dispatch(getDownloadsAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchPimlogsAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance.get(`/admin/logs`).then((response) => {
      if (response) {
        dispatch(getPimlogsAdmin(response.data));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const fetchPimlogsAdminSite = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/logs`)
      .then((response) => dispatch(getPimlogsAdminsite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchPimErrorAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/admin/unprocessedpim`)
      .then((response) => dispatch(getPimErrorAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(`organisations`, data)
      .then((response) => dispatch(addOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .put(`organisations/${data.id_orga}`, data)
      .then((response) => dispatch(updateStateOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(`organisations/${data.id_orga}`)
      .then((response) => dispatch(removeOrga(data.id_orga)));
  } catch (e) {
    return console.error(e.message);
  }
};

//Take Care of Orga selection when you are admin
export const createSite = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(`organisations/${data.id_orga}/sites`, data)
      .then((response) => dispatch(addSite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};
export const attachDeviceSite = (data, payload) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(
        `organisations/${data.id_orga}/sites/${data.data_type.slice(5)}/attach`,
        payload
      )
      .then((response) => dispatch(attachdevicesite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};
export const detachDeviceSite = (data, payload) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(
        `organisations/${data.id_orga}/sites/${data.data_type.slice(5)}/detach`,
        payload
      )
      .then((response) => dispatch(detachdevicesite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateSite = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .put(
        `organisations/${data.id_orga}/sites/${data.data_type.slice(5)}`,
        data
      )
      .then((response) => dispatch(updateStateSite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteSite = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(`organisations/${data.id_orga}/sites/${data.data_type.slice(5)}`)
      .then((response) => dispatch(removeSite(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

//Take Care of Orga selection when you are admin
export const createDevice = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(`organisations/${data.id_orga}/devices`, data)
      .then((response) => dispatch(addDevice(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateDevice = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .put(
        `organisations/${data.id_orga}/devices/${data.data_type.slice(7)}`,
        data
      )
      .then((response) => dispatch(updateStateDevice(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteDevice = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(
        `organisations/${data.id_orga}/devices/${data.data_type.slice(7)}`
      )
      .then((response) => dispatch(removeDevice(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

//Take Care of Orga selection when you are admin
export const createUser = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(`organisations/${data.id_orga}/users`, data)
      .then((response) => dispatch(addUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateUser = (data) => async (dispatch) => {
  CheckConfigAxios();

  console.log(`organisations/${data.id_orga}/users/${data.data_type.slice(5)}`);

  try {
    axiosInstance
      .put(
        `organisations/${data.id_orga}/users/${data.data_type.slice(5)}`,
        data
      )
      .then((response) => dispatch(updateStateUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteUser = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(`organisations/${data.id_orga}/users/${data.data_type.slice(5)}`)
      .then((response) => dispatch(removeUser(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDatas = (begin, end, device, id_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(
        `organisations/${id_orga}/devices/${device}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
      )
      .then((response) => dispatch(getDatas(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDatasWithTime =
  (begin, end, device) => async (dispatch, getState) => {
    CheckConfigAxios();

    const user = getState().ttdatahub.user;

    try {
      axiosInstance

        .get(
          `organisations/${user.id_orga}/devices/${device}/datas?begin_iso8601=${begin}&end_iso8601=${end}&bin=1d`
        )

        .then((response) => dispatch(getDatas(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasSite =
  (begin, end, site, id_orga) => async (dispatch) => {
    CheckConfigAxios();
    try {
      axiosInstance
        .get(
          `organisations/${id_orga}/sites/${site}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
        )
        .then((response) => dispatch(getDatasite(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

// export const fetchDatasSite =
//   (begin, end, site, id_orga) => async (dispatch) => {
//     CheckConfigAxios();
//     try {
//       const response = await axiosInstance.get(
//         `organisations/${id_orga}/sites/${site}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
//       );
//       dispatch(getDatasite(response.data));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

export const fetchPimlogs =
  (begin, end, device, id_orga) => async (dispatch) => {
    CheckConfigAxios();
    try {
      axiosInstance
        .get(
          `organisations/${id_orga}/devices/${device}/pimlog?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00`
        )
        .then((response) => dispatch(getPimlogs(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchPimlogssite =
  (begin, end, sites, id_orga) => async (dispatch) => {
    CheckConfigAxios();
    try {
      axiosInstance
        .get(
          `organisations/${id_orga}/sites/${sites}/pimlog?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00`
        )
        .then((response) => dispatch(getPimlogsite(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasDay = (day, device, id_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(
        `organisations/${id_orga}/devices/${device}/datas?begin_iso8601=${day}T00:00:00&end_iso8601=${day}T23:59:59&bin=1h`
      )
      .then((response) => dispatch(getDatasDay(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDatasDaySite = (day, site, id_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(
        `organisations/${id_orga}/sites/${site}/datas?begin_iso8601=${day}T00:00:00&end_iso8601=${day}T23:59:59&bin=1h`
      )
      .then((response) => dispatch(getDatasDaySite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const exportDatas =
  (begin, end, device, id_orga, bin, mail_to, format, local_timezone) =>
  async (dispatch) => {
    CheckConfigAxios();
    try {
      axiosInstance
        .get(
          `organisations/${id_orga}/devices/${device}/exportdatas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T23:59:00&bin=${bin}&mail_to=${mail_to}&format=${format}&local_timezone=${local_timezone}`
        )
        .then((response) => dispatch(getDataExport(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const exportDataSite =
  (begin, end, site, id_orga, bin, mail_to, format, local_timezone) =>
  async (dispatch) => {
    CheckConfigAxios();
    try {
      axiosInstance
        .get(
          `organisations/${id_orga}/sites/${site}/exportdatas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T23:59:00&bin=${bin}&mail_to=${mail_to}&format=${format}&local_timezone=${local_timezone}`
        )
        .then((response) => dispatch(getDataExport(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };
