import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import dashboardItemsAdmin from "../components/sidebar/dashboardItemsAdmin";
import dashboardItemsDistri from "../components/sidebar/dashboardItemsDistri";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
//import Settings from "../components/Settings";

import useAuth from "../hooks/useAuth";

const drawerWidth = 250;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(dashboardItems);
  const { isAuthenticated } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const id_orga = localStorage.getItem("id_orga");

    if (id_orga === "*") {
      setSelectedMenu(dashboardItemsAdmin);
    } else {
      if (id_orga === "$") {
        setSelectedMenu(dashboardItemsDistri);
      } else {
        setSelectedMenu(dashboardItems);
      }
    }

    setTimeout(() => {
      const id_orga = localStorage.getItem("id_orga");

      if (id_orga === "*") {
        setSelectedMenu(dashboardItemsAdmin);
      } else {
        if (id_orga === "$") {
          setSelectedMenu(dashboardItemsDistri);
        } else {
          setSelectedMenu(dashboardItems);
        }
      }
    }, 500);

    setTimeout(() => {
      const id_orga = localStorage.getItem("id_orga");

      if (id_orga === "*") {
        setSelectedMenu(dashboardItemsAdmin);
      } else {
        if (id_orga === "$") {
          setSelectedMenu(dashboardItemsDistri);
        } else {
          setSelectedMenu(dashboardItems);
        }
      }
    }, 3000);
  }, [isAuthenticated]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={selectedMenu}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={selectedMenu}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={8}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
