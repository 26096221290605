import axios from "axios";
//import { isValidToken } from "./jwt";
// Default config options
const defaultOptions = {
  baseURL: "https://api.tt-datahub.com",
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
let axiosInstance = axios.create(defaultOptions);

/*axiosInstance.interceptors.request.use((request) => {
  console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});*/

/*axiosInstance.interceptors.request.use(async (request) => {
  if (axiosInstance.defaults.headers.common.Authorization == null) {
    console.log("NO TOKEN IN AXIOS CONFIG, NEED TO LOAD ONE !");
    let token = localStorage.getItem("accessToken");
    console.log(token);
    if (isValidToken(token)) {
      axiosInstance.defaults.headers.common.Authorization = token;
    } else {
      console.log("YOU HAVE TO RECONNECT");
    }
  } else {
    console.log(
      "TOKEN VALIDY IS : ",
      isValidToken(axiosInstance.defaults.headers.common.Authorization)
    );
  }

  return request;
});*/

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => console.log(error)
  //Promise.reject(
  //  (error.response && error.response.data) || "Something went wrong"
  //)
);

export default axiosInstance;
