import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

//Slice

const slice = createSlice({
  name: "ttdatahub",
  initialState: {
    user: null,
    orga: null,
    sites: [],
    devices: [],
    users: [],
    files: [],
    datas: [],
    pimlogs: [],
    datasday: [],
    dataexport: [],
    datasite: [],
    datasdaysite: [],
  },
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getOrga: (state, action) => {
      state.orga = action.payload;
    },
    getUsers: (state, action) => {
      state.users = action.payload;
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateUser: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getSites: (state, action) => {
      state.sites = action.payload;
    },
    addSite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
    removeSite: (state, action) => {
      state.sites = state.sites.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateSite: (state, action) => {
      state.sites = state.sites.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getDevices: (state, action) => {
      state.devices = action.payload;
    },
    getFiles: (state, action) => {
      state.files = action.payload;
    },
    getDatas: (state, action) => {
      state.datas = action.payload;
    },
    getPimlogs: (state, action) => {
      state.pimlogs = action.payload;
    },
    getDatasDay: (state, action) => {
      state.datasday = action.payload;
    },
    getDataExport: (state, action) => {
      state.dataexport = action.payload;
    },
    getDatasite: (state, action) => {
      state.datasite = action.payload;
    },
    getDatasDaySite: (state, action) => {
      state.datasdaysite = action.payload;
    },
    attachdevicesite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
    detachdevicesite: (state, action) => {
      state.sites = [...state.sites, action.payload];
    },
  },
});

export default slice.reducer;

const {
  getUser,
  getOrga,
  getUsers,
  addUser,
  removeUser,
  updateStateUser,
  getSites,
  addSite,
  removeSite,
  updateStateSite,
  getDevices,
  getFiles,
  getDatas,
  getPimlogs,
  getDatasDay,
  getDataExport,
  getDatasite,
  getDatasDaySite,
  attachdevicesite,
  detachdevicesite,
} = slice.actions;

function CheckConfigAxios() {
  //We check if token is loaded into Axios Config
  if (axiosInstance.defaults.headers.common.Authorization == null) {
    //console.log("NO TOKEN IN AXIOS CONFIG, NEED TO LOAD ONE !");
    let token = localStorage.getItem("accessToken");
    if (isValidToken(token)) {
      axiosInstance.defaults.headers.common.Authorization = token;
    } else {
      console.log("YOU HAVE TO RECONNECT");
    }
  } else {
    if (!isValidToken(axiosInstance.defaults.headers.common.Authorization)) {
      console.log("YOU HAVE TO RECONNECT");
    }
  }
}

//Actions
export const fetchUser = () => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance.get("/userinfo").then((response) => {
      if (response) {
        dispatch(getUser(response.data));
        localStorage.setItem("id_orga", response.data.id_orga);

        dispatch(fetchOrga());
        dispatch(fetchSites());
        dispatch(fetchDevices());
        dispatch(fetchUsers());
        dispatch(fetchFiles());
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchOrga = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user.id_orga === "*") {
    dispatch(getOrga({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}`)
      .then((response) => dispatch(getOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchUsers = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user === null) {
    dispatch(getUsers({ Message: "No Data" }));
    return;
  }
  if (user !== null) {
    if (user.id_orga === "*") {
      dispatch(getUsers({ Message: "No Data" }));
      return;
    }
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}/users`)
      .then((response) => dispatch(getUsers(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .post(`organisations/${user.id_orga}/users`, data)
      .then((response) => dispatch(addUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .put(
        `organisations/${user.id_orga}/users/${data.data_type.slice(5)}`,
        data
      )
      .then((response) => dispatch(updateStateUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .delete(`organisations/${user.id_orga}/users/${data.data_type.slice(5)}`)
      .then((response) => dispatch(removeUser(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchSites = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user === null) {
    dispatch(getSites({ Message: "No Data" }));
    return;
  }
  if (user !== null) {
    if (user.id_orga === "*") {
      dispatch(getSites({ Message: "No Data" }));
      return;
    }
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}/sites`)
      .then((response) => dispatch(getSites(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createSite = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .post(`organisations/${user.id_orga}/sites`, data)
      .then((response) => dispatch(addSite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateSite = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .put(
        `organisations/${user.id_orga}/sites/${data.data_type.slice(5)}`,
        data
      )
      .then((response) => dispatch(updateStateSite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteSite = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .delete(`organisations/${user.id_orga}/sites/${data.data_type.slice(5)}`)
      .then((response) => dispatch(removeSite(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDevices = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user.id_orga === "*") {
    dispatch(getDevices({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}/devices`)
      .then((response) => dispatch(getDevices(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchFiles = () => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  if (user.id_orga === "*") {
    dispatch(getFiles({ Message: "No Data" }));
    return;
  }
  try {
    axiosInstance
      .get(`/organisations/${user.id_orga}/files`)
      .then((response) => dispatch(getFiles(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDatas =
  (begin, end, device) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
        )
        .then((response) => dispatch(getDatas(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchPimlogs =
  (begin, end, device) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/pimlog?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00`
        )
        .then((response) => dispatch(getPimlogs(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasDay = (day, device) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .get(
        `organisations/${user.id_orga}/devices/${device}/datas?begin_iso8601=${day}T00:00:00&end_iso8601=${day}T23:59:59&bin=1h`
      )
      .then((response) => dispatch(getDatasDay(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const exportDatas =
  (begin, end, device, bin, mail_to, format, local_timezone) =>
  async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/devices/${device}/exportdatas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T23:59:00&bin=${bin}&mail_to=${mail_to}&format=${format}&local_timezone=${local_timezone}`
        )
        .then((response) => dispatch(getDataExport(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasSite =
  (begin, end, site) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/sites/${site}/datas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T20:00:00&bin=1d`
        )
        .then((response) => dispatch(getDatasite(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchDatasDaySite = (day, site) => async (dispatch, getState) => {
  CheckConfigAxios();
  const user = getState().ttdatahub.user;
  try {
    axiosInstance
      .get(
        `organisations/${user.id_orga}/sites/${site}/datas?begin_iso8601=${day}T00:00:00&end_iso8601=${day}T23:59:59&bin=1h`
      )
      .then((response) => dispatch(getDatasDaySite(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const exportDataSite =
  (begin, end, device, bin, mail_to, format, local_timezone) =>
  async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .get(
          `organisations/${user.id_orga}/sites/${device}/exportdatas?begin_iso8601=${begin}T00:00:00&end_iso8601=${end}T23:59:00&bin=${bin}&mail_to=${mail_to}&format=${format}&local_timezone=${local_timezone}`
        )
        .then((response) => dispatch(getDataExport(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const attachDeviceSite =
  (data, payload) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .post(
          `organisations/${user.id_orga}/sites/${data.data_type.slice(
            5
          )}/attach`,
          payload
        )
        .then((response) => dispatch(attachdevicesite(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };
export const detachDeviceSite =
  (data, payload) => async (dispatch, getState) => {
    CheckConfigAxios();
    const user = getState().ttdatahub.user;
    try {
      axiosInstance
        .post(
          `organisations/${user.id_orga}/sites/${data.data_type.slice(
            5
          )}/detach`,
          payload
        )
        .then((response) => dispatch(detachdevicesite(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };
