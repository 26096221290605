import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import ttdatahubReducer from "./slices/ttdatahub";
import admindatahubReducer from "./slices/admindatahub";
import distridatahub from "./slices/distridatahub";

export const store = configureStore(
  {
    reducer: {
      ttdatahub: ttdatahubReducer,
      admindatahub: admindatahubReducer,
      distridatahub: distridatahub,
    },
  },
  composeWithDevTools()
);
