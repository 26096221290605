import {
  Users,
  Database,
  Download,
  Grid,
  Crosshair,
  MapPin,
} from "react-feather";
// import "../../../src/.css";

const distriSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Home",
  },
  {
    href: "/distriorganisations",
    icon: Database,
    title: "Organisations",
  },
  {
    href: "/distridevices",
    icon: Crosshair,
    title: "Devices",
  },
  {
    href: "/distrisites",
    icon: MapPin,
    title: "Sites",
  },
  {
    href: "/distriusers",
    icon: Users,
    title: "Users",
  },
  {
    href: "/distridownloads",
    icon: Download,
    title: "Downloads",
  },
];

const navItems = [
  {
    title: "Distributor",
    pages: distriSection,
    // className: "sidebar-title",
    style: {
      fontWeight: "bold",
    },
  },
];

export default navItems;
