import {
  Users,
  Database,
  Eye,
  Download,
  Grid,
  Crosshair,
  MapPin,
} from "react-feather";
// import "../../../src/.css";

const adminSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Home",
  },
  {
    href: "/adminorganisations",
    icon: Database,
    title: "Organisations",
  },
  {
    href: "/admindevices",
    icon: Crosshair,
    title: "Devices",
  },
  {
    href: "/adminsites",
    icon: MapPin,
    title: "Sites",
  },
  {
    href: "/adminusers",
    icon: Users,
    title: "Users",
  },
  {
    href: "/admindownloads",
    icon: Download,
    title: "Downloads",
  },
  {
    href: "/adminmonitoring",
    icon: Eye,
    title: "Monitoring",
  },
];

const navItems = [
  {
    title: "Administrator",
    pages: adminSection,
    // className: "sidebar-title",
    style: {
      fontWeight: "bold",
    },
  },
];

export default navItems;
