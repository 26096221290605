import { Grid, Users, MapPin, Download, Crosshair } from "react-feather";

const pagesSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Home",
  },
  {
    href: "/devices",
    icon: Crosshair,
    title: "Devices",
  },
  {
    href: "/sites",
    icon: MapPin,
    title: "Sites",
  },
  {
    href: "/users",
    icon: Users,
    title: "Users",
  },
  {
    href: "/downloads",
    icon: Download,
    title: "Downloads",
  },
];

const navItems = [
  {
    title: "USER",
    pages: pagesSection,
  },
];

export default navItems;
