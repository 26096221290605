import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

//User Page
const Home = async(() => import("./pages/home"));
const Devices = async(() => import("./pages/userDashboard/Devices"));
const Sites = async(() => import("./pages/userDashboard/Sites"));
const DevicesDetails = async(() =>
  import("./pages/userDashboard/DevicesDetails")
);
const SitesDetails = async(() => import("./pages/userDashboard/SitesDetails"));
const Users = async(() => import("./pages/userDashboard/Users"));
const Downloads = async(() => import("./pages/userDashboard/Downloads"));

//Admin Pages
const OrgasAdmin = async(() =>
  import("./pages/adminDashboard/OrganisationsAdmin")
);
const SitesAdmin = async(() => import("./pages/adminDashboard/SitesAdmin"));
const SitesDetailsAdmin = async(() =>
  import("./pages/adminDashboard/SitesDetailsAdmin")
);
const DevicesAdmin = async(() => import("./pages/adminDashboard/DevicesAdmin"));
const DevicesDetailsAdmin = async(() =>
  import("./pages/adminDashboard/DevicesDetailsAdmin")
);
const UsersAdmin = async(() => import("./pages/adminDashboard/UsersAdmin"));
const DownloadsAdmin = async(() =>
  import("./pages/adminDashboard/DownloadsAdmin")
);
const Monitoring = async(() => import("./pages/adminDashboard/Monitoring"));

//Distri Pages
const OrgasDistri = async(() =>
  import("./pages/distriDashboard/OrganisationsDistri")
);
const SitesDistri = async(() => import("./pages/distriDashboard/SitesDistri"));
const SitesDetailsDistri = async(() =>
  import("./pages/distriDashboard/SitesDetailsDistri")
);
const DevicesDistri = async(() =>
  import("./pages/distriDashboard/DevicesDistri")
);
const DevicesDetailsDistri = async(() =>
  import("./pages/distriDashboard/DevicesDetailsDistri")
);
const UsersDistri = async(() => import("./pages/distriDashboard/UsersDistri"));
const DownloadsDistri = async(() =>
  import("./pages/distriDashboard/DownloadsDistri")
);

// Icon components
//const Profile = async(() => import("./pages/pages/Profile"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "home",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "devices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Devices />,
      },
      {
        path: ":id",
        element: <DevicesDetails />,
      },
    ],
  },
  {
    path: "sites",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Sites />,
      },
      {
        path: ":id",
        element: <SitesDetails />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
    ],
  },
  {
    path: "downloads",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Downloads />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword stage={1} />,
      },
      {
        path: "reset-password-code",
        element: <ResetPassword stage={2} />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "adminsites",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SitesAdmin />,
      },
      {
        path: ":orga/:id",
        element: <SitesDetailsAdmin />,
      },
    ],
  },
  {
    path: "adminorganisations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrgasAdmin />,
      },
    ],
  },
  {
    path: "admindevices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DevicesAdmin />,
      },
      {
        path: ":orga/:id",
        element: <DevicesDetailsAdmin />,
      },
    ],
  },

  {
    path: "adminusers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UsersAdmin />,
      },
    ],
  },
  {
    path: "admindownloads",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DownloadsAdmin />,
      },
    ],
  },
  {
    path: "adminmonitoring",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Monitoring />,
      },
    ],
  },
  {
    path: "organisations/:id_orga/devices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: "",
      //   element: <DevicesAdmin />,
      // },
      {
        path: ":id/:orga",
        element: <DevicesDetailsAdmin />,
      },
    ],
  },
  {
    path: "distrisites",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SitesDistri />,
      },
      {
        path: ":orga/:id",
        element: <SitesDetailsDistri />,
      },
    ],
  },
  {
    path: "distriorganisations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrgasDistri />,
      },
    ],
  },
  {
    path: "distridevices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DevicesDistri />,
      },
      {
        path: ":orga/:id",
        element: <DevicesDetailsDistri />,
      },
    ],
  },

  {
    path: "distriusers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UsersDistri />,
      },
    ],
  },
  {
    path: "distridownloads",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DownloadsDistri />,
      },
    ],
  },
  {
    path: "organisations/:id_orga/devices",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: "",
      //   element: <DevicesDistri />,
      // },
      {
        path: ":id/:orga",
        element: <DevicesDetailsDistri />,
      },
    ],
  },
];

export default routes;
